import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { getCommonFilters, getCommonProperties, getLastCommonProperties } from './BaseProcedureMetadata';
import { OemMetadata, buildOemMetadata } from './BuildOemMetadata';
import { OemEngineService, OemMappingRuleBase } from './types';
import { buildProcedureProperty } from './ProcedureProperty';
import { PROPERTY_TYPE } from './PropertyType';
import { DataSource } from 'components/locations/MappingProcess/Procedures/MappingProceduresTool';

const OEM_METADATA_PROPERTY = 'latestVWProcedure';

const getRulableProperties = () => [
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.mainGroup',
        displayName: 'Main Group',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'mainGroup',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.repairGroup',
        displayName: 'Repair Group',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'repairGroup',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.publicationGroupTitle',
        displayName: 'Publication Group Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'publicationGroupTitle',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.mainChapterTitle',
        displayName: 'Main Chapter Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'mainChapterTitle',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.procedureTitle',
        displayName: 'Procedure Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'procedureTitle',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.manualType',
        displayName: 'Manual Type',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'manualType',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.chapterType',
        displayName: 'Chapter Type',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'chapterType',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.chapterTitle',
        displayName: 'Chapter Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'chapterTitle',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.typeKeywords',
        displayName: 'Type Keywords',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'typeKeywords',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.isLinksOnly',
        displayName: 'Links Only',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'isLinksOnly',
    }),
];

const getNonRulableProperties = () => [
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.vwProcedureId',
        displayName: 'VW Procedure Id',
        type: PROPERTY_TYPE.number,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.oemProcedureId',
        displayName: 'Oem Procedure Id',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.secondaryId',
        displayName: 'Secondary Id',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.version',
        displayName: 'Version',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.htmlFileName',
        displayName: 'HTML File Name',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.originalFileName',
        displayName: 'Original File Name',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.externalId',
        displayName: 'External Id',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.rawExternalId',
        displayName: 'Raw External Id',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.workshopTaskId',
        displayName: 'Workshop Task Id',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.externalChapterNumber',
        displayName: 'External Chapter Number',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.publicationGroupNumber',
        displayName: 'Publication Group Number',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.typeCode',
        displayName: 'Type Code',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.salesType',
        displayName: 'Sales Type',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.engineCode',
        displayName: 'Engine Code',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.transmissionCode',
        displayName: 'Transmission Code',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.partCode',
        displayName: 'Part Code',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.modelYear',
        displayName: 'Model Year',
        type: PROPERTY_TYPE.string,
    }),
];

export const getVWProcedureMetadata = (dataSource: DataSource) =>
    buildOemMetadata({
        oemId: 26,
        oemName: 'VW',
        properties: [
            ...getCommonProperties(),
            ...getRulableProperties(),
            ...getNonRulableProperties(),
            ...getLastCommonProperties(dataSource),
        ],
        ruleKey: 'vwMappingRuleId',
        filters: [...getCommonFilters(dataSource)],
    });

interface VWMappingRule extends OemMappingRuleBase {
    vwMappingRuleId: number;
}

class _VWMappingEngineService implements OemEngineService<VWMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = VW_PROCEDURE_METADATA_SQL;
    }

    isRuleValid = (rule: VWMappingRule): boolean =>
        rule.typeId && rule.groupIds.length && this.metadata.ruleable.some(r => rule[r.rulePropertyName]);

    createRule = async (rule: VWMappingRule): Promise<VWMappingRule> => {
        const newRule = { ...rule };
        const vwMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.vwMappingRuleId = vwMappingRuleId;

        return newRule;
    };
}

export const VW_PROCEDURE_METADATA_SQL = getVWProcedureMetadata(DataSource.SQL);
export const VW_PROCEDURE_METADATA_ES = getVWProcedureMetadata(DataSource.ES);
export const VWMappingEngineService = new _VWMappingEngineService();
