import { buildProcedureProperty } from './ProcedureProperty';
import { getCommonFilters, getCommonProperties, getLastCommonProperties } from './BaseProcedureMetadata';
import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { buildOemMetadata, OemMetadata } from './BuildOemMetadata';
import { isEmpty, isNil } from 'lodash';
import { PROPERTY_TYPE } from './PropertyType';
import { OemEngineService, OemMappingRuleBase } from './types';
import { DataSource } from 'components/locations/MappingProcess/Procedures/MappingProceduresTool';

const OEM_METADATA_PROPERTY = 'latestGMProcedure';

const getRulableProperties = () => [
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.deliverySectionTitle',
        displayName: 'Delivery Section Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'deliverySectionTitle',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.deliverySubSectionTitle',
        displayName: 'Delivery SubSection Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'deliverySubSectionTitle',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.deliverySITTitle',
        displayName: 'Delivery SIT Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'deliverySITTitle',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.title',
        displayName: 'Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'title',
    }),
];

const getOemTsbProperties = () => [
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.bulletinType',
        displayName: 'Bulletin Type',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.bulletinNbr',
        displayName: 'Bulletin Number',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.supercedeBulletinNbr',
        displayName: 'Supercede Bulletin Number',
        type: PROPERTY_TYPE.string,
    }),
];

export const getGMProcedureMetadata = (dataSource: DataSource) =>
    buildOemMetadata({
        oemId: 11,
        oemName: 'GM',
        properties: [
            ...getCommonProperties(),
            ...getRulableProperties(),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.oemProcedureId',
                displayName: 'OEM Procedure ID',
                type: PROPERTY_TYPE.string,
            }),
            ...getOemTsbProperties(),
            ...getLastCommonProperties(dataSource),
        ],
        ruleKey: 'gmMappingRuleId',
        filters: [...getCommonFilters(dataSource)],
    });

type GMMappingRule = OemMappingRuleBase & {
    gmMappingRuleId: number;
};

class _GMMappingEngineService implements OemEngineService<GMMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = GM_PROCEDURE_METADATA_SQL;
    }

    isRuleValid = (rule: GMMappingRule): boolean => {
        return (
            (!isNil(rule.typeId) || !isEmpty(rule.groupIds)) &&
            this.metadata.ruleable.some(p => rule[p.rulePropertyName])
        );
    };

    createRule = async (rule: GMMappingRule): Promise<GMMappingRule> => {
        const newRule = { ...rule };
        const gmMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.gmMappingRuleId = gmMappingRuleId;

        return newRule;
    };
}

export const GM_PROCEDURE_METADATA_SQL = getGMProcedureMetadata(DataSource.SQL);
export const GM_PROCEDURE_METADATA_ES = getGMProcedureMetadata(DataSource.ES);
export const GMMappingEngineService = new _GMMappingEngineService();
