import { buildProcedureProperty } from './ProcedureProperty';
import { getCommonFilters, getCommonProperties, getLastCommonProperties } from './BaseProcedureMetadata';
import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { buildOemMetadata, OemMetadata } from './BuildOemMetadata';
import { isEmpty, isNil } from 'lodash';
import { PROPERTY_TYPE } from './PropertyType';
import { OemEngineService, OemMappingRuleBase } from './types';
import { DataSource } from 'components/locations/MappingProcess/Procedures/MappingProceduresTool';

const OEM_METADATA_PROPERTY = 'latestStellantisProcedure';

const getRulableProperties = () => [
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.systems',
        displayName: 'Systems',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'systems',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.subSystems',
        displayName: 'Sub Systems',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'subSystems',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.multiSubSystems',
        displayName: 'Multi SubSystems',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'multiSubSystems',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.components',
        displayName: 'Components',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'components',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.title',
        displayName: 'Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'title',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.infoTypes',
        displayName: 'Info Types',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'infoTypes',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.salesCodes',
        displayName: 'Sales Codes',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'salesCodes',
    }),
];

export const getStellantisProcedureMetadata = (dataSource: DataSource) =>
    buildOemMetadata({
        oemId: 2,
        oemName: 'Stellantis',
        properties: [
            ...getCommonProperties(),
            ...getRulableProperties(),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.stellantisProcedureId',
                displayName: 'Stellantis Procedure Id',
                type: PROPERTY_TYPE.number,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.oemProcedureId',
                displayName: 'Oem Procedure Id',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.version',
                displayName: 'Version',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.htmlFileName',
                displayName: 'Html File Name',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.procedureTitle',
                displayName: 'Procedure Title',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.multiSystems',
                displayName: 'Multi Systems',
                type: PROPERTY_TYPE.string,
            }),
            ...getLastCommonProperties(dataSource),
        ],
        ruleKey: 'stellantisMappingRuleId',
        filters: [...getCommonFilters(dataSource)],
    });

type StellantisMappingRule = OemMappingRuleBase & {
    stellantisMappingRuleId: number;
};

class _StellantisMappingEngineService implements OemEngineService<StellantisMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = STELLANTIS_PROCEDURE_METADATA_SQL;
    }

    isRuleValid = (rule: StellantisMappingRule): boolean => {
        return (
            (!isNil(rule.typeId) || !isEmpty(rule.groupIds)) &&
            this.metadata.ruleable.some(p => rule[p.rulePropertyName])
        );
    };

    createRule = async (rule: StellantisMappingRule): Promise<StellantisMappingRule> => {
        const newRule = { ...rule };
        const stellantisMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.stellantisMappingRuleId = stellantisMappingRuleId;

        return newRule;
    };
}

export const STELLANTIS_PROCEDURE_METADATA_SQL = getStellantisProcedureMetadata(DataSource.SQL);
export const STELLANTIS_PROCEDURE_METADATA_ES = getStellantisProcedureMetadata(DataSource.ES);
export const StellantisMappingEngineService = new _StellantisMappingEngineService();
