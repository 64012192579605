import { isNil } from 'lodash';
import { PROPERTY_TYPE, PropertyType } from './PropertyType';
import { operatorsList } from 'components/Shared/TableFilters/operators';

export type Operator = {
    value: string;
    label: string;
};

export type ProcedurePropertyMetadata<TProperty = unknown, TValue = TProperty> = {
    id: string;
    name: string;
    displayName: string;
    type: PropertyType<TValue>;
    enabled: boolean;
    searchable: boolean;
    sortable: boolean;
    ruleable: boolean;
    rulePropertyName: string;
    ruleableOperators: Operator[];
    multiple: boolean;
    valueProvider: (v: TProperty) => TValue;
};

export type InputMetadata<TProperty = unknown, TValue = TProperty> = {
    id?: string;
    name: string;
    displayName?: string;
    type?: PropertyType<TValue>;
    enabled?: boolean;
    searchable?: boolean;
    sortable?: boolean;
    ruleable?: boolean;
    rulePropertyName?: string;
    ruleableOperators?: Operator[];
    multiple?: boolean;
    valueProvider?: (v: TProperty) => TValue;
};

export const buildProcedureProperty = <TProperty = unknown, TValue = TProperty>(
    metadataProperties: InputMetadata<TProperty, TValue>
): ProcedurePropertyMetadata<TProperty, TValue> => {
    const {
        id,
        name,
        displayName,
        type,
        enabled,
        searchable,
        sortable,
        ruleable,
        rulePropertyName,
        ruleableOperators,
        multiple,
        valueProvider,
    } = metadataProperties;

    const isEnabled = enabled ?? true;
    const isRuleable = ruleable ?? !isNil(rulePropertyName);

    return {
        id: id ?? name,
        name,
        displayName: displayName ?? name,
        type: type ?? (PROPERTY_TYPE.string as unknown as PropertyType<TValue>),
        enabled: isEnabled,
        searchable: isEnabled && (searchable ?? true),
        sortable: isEnabled && (sortable ?? true),
        ruleable: isRuleable,
        rulePropertyName: isRuleable ? rulePropertyName ?? name : null,
        ruleableOperators: isRuleable ? ruleableOperators ?? [operatorsList.eq] : [],
        multiple: multiple ?? false,
        valueProvider: valueProvider ?? (v => v as unknown as TValue),
    };
};
