import { OemMetadata, FilterProperty } from 'hooks/OemModels/MetaModel/BuildOemMetadata';

export const mapSearchablePropertiesToFilters = properties =>
    properties
        .filter(p => p.searchable)
        .map(c => ({
            id: c.id ? c.id : c.name,
            property: c.name,
            label: c.displayName,
            type: c.type.filterType,
            ruleable: c.ruleable,
            allowInstances: 10,
        }));

const buildFiltersConfiguration = (oemMetadata: OemMetadata): FilterProperty[] => {
    return mapSearchablePropertiesToFilters(oemMetadata.properties).concat(oemMetadata.filters);
};

export default buildFiltersConfiguration;
