import { buildOemMetadata, OemMetadata } from './BuildOemMetadata';
import { buildProcedureProperty } from './ProcedureProperty';
import { PROPERTY_TYPE } from './PropertyType';
import { getCommonFilters, getCommonProperties, getLastCommonProperties } from './BaseProcedureMetadata';
import { OemEngineService, OemMappingRuleBase } from './types';
import { isEmpty, isNil } from 'lodash';
import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { DataSource } from 'components/locations/MappingProcess/Procedures/MappingProceduresTool';

const OEM_METADATA_PROPERTY = 'latestOemIqFordProcedure';

const getRulableProperties = () => [
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.system',
        displayName: 'System',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'system',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.group',
        displayName: 'Group',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'group',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.section',
        displayName: 'Section',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'section',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.procTitle',
        displayName: 'Proc Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'procTitle',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.sectionTitle',
        displayName: 'Section Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'sectionTitle',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.subSectionTitle',
        displayName: 'SubSection Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'subSectionTitle',
    }),
];

export const getOemIqFordProcedureMetadata = (dataSource: DataSource) =>
    buildOemMetadata({
        oemId: 100,
        oemName: 'OemIqFord',
        properties: [
            ...getCommonProperties(),
            ...getRulableProperties(),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.oemIqFordProcedureId',
                displayName: 'OEM Iq Ford Procedure Id',
                type: PROPERTY_TYPE.number,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.oemProcedureId',
                displayName: 'Oem Procedure Id',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.variantId',
                displayName: 'Variant Id',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.bookCode',
                displayName: 'Book Code',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.market',
                displayName: 'Market',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.language',
                displayName: 'Language',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.year',
                displayName: 'Year',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.modelName',
                displayName: 'Model Name',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.procId',
                displayName: 'Proc Id',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.revisionDate',
                displayName: 'Revision Date',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.type',
                displayName: 'Type',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.procSMGLId',
                displayName: 'Proc SMGL Id',
                type: PROPERTY_TYPE.string,
            }),
            ...getLastCommonProperties(dataSource),
        ],
        ruleKey: 'fordMappingRuleId',
        filters: [...getCommonFilters(dataSource)],
    });

type OemIqFordMappingRule = OemMappingRuleBase & {
    fordMappingRuleId: number;
};

class _OemIqFordMappingEngineService implements OemEngineService<OemIqFordMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = OEMIQ_FORD_PROCEDURE_METADATA_SQL;
    }

    isRuleValid = (rule: OemIqFordMappingRule): boolean => {
        return (
            (!isNil(rule.typeId) || !isEmpty(rule.groupIds)) &&
            this.metadata.ruleable.some(p => rule[p.rulePropertyName])
        );
    };

    createRule = async (rule: OemIqFordMappingRule): Promise<OemIqFordMappingRule> => {
        const newRule = { ...rule };
        const fordMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.fordMappingRuleId = fordMappingRuleId;

        return newRule;
    };
}
export const OEMIQ_FORD_PROCEDURE_METADATA_SQL = getOemIqFordProcedureMetadata(DataSource.SQL);
export const OEMIQ_FORD_PROCEDURE_METADATA_ES = getOemIqFordProcedureMetadata(DataSource.ES);
export const OemIqFordMappingEngineService = new _OemIqFordMappingEngineService();
