import { OemId } from 'helpers/OemId';
import { RequestRestGetWithOdataParams } from '../RequestRestGetWithOdataParams';
import {
    RpFordProcedure,
    RpGMProcedure,
    RpHondaProcedure,
    RpNissanProcedure,
    RpOemIqFordProcedure,
    RpStellantisProcedure,
    RpSubaruProcedure,
    RpToyotaProcedure,
    RpVWProcedure,
    RpHyundaiProcedure,
} from './OemProcedureTypes';
import { fetchWithAuthHeader } from 'api/AuthUtils';
import { OdataResponse } from './types';
import { SORT_ORDER, SortOrderClause } from 'enums/SortOrderEnum';
import { FilterCheckboxes, FilterClause } from 'components/locations/MappingProcess/Procedures/MappingProceduresTool';
import { orderToQueryTranslations } from 'components/Shared/TableFilters/Types/orderToQueryTranslations';
import {
    buildESFiltersFromCheckboxes,
    buildSQLFiltersFromCheckboxes,
} from 'components/locations/MappingProcess/Procedures/MappingRuleFilters/ApplyFilters';
import { filterToQueryTranslations } from 'components/Shared/TableFilters/filterToQueryTranslations';
import { match } from 'ts-pattern';

type SearchParams = {
    queryString: string;
    filters: string[];
    operator: string;
    searchFields: string[];
    returnFields: string[];
    skip: number;
    top: number;
    sorts: {
        field: string;
        order: string;
    }[];
    count: boolean;
};

export const esClauseTranslator = {
    orderBy: (orderBy: SortOrderClause[]) => {
        if (orderBy === null) return [];

        const translateSortOrderForES = (sortOrder: SORT_ORDER) =>
            match(sortOrder)
                .with(SORT_ORDER.asc, () => 'Ascending')
                .with(SORT_ORDER.desc, () => 'Descending')
                .with(SORT_ORDER.none, () => '')
                .exhaustive();

        const esOrderBy = orderBy.map(o => {
            const orderName = orderToQueryTranslations[o.elementId]?.esOrderBy ?? o.elementId;
            return { field: orderName, order: translateSortOrderForES(o.order) };
        });
        return esOrderBy;
    },
    filterBy: (dataFilters: FilterClause[], filterCheckboxes: FilterCheckboxes | null) => {
        const checkboxFilters = filterCheckboxes != null ? buildESFiltersFromCheckboxes(filterCheckboxes) : [];
        const mainFilters = dataFilters
            .map(fc => {
                const translator = filterToQueryTranslations[fc.operator.value];
                return translator.buildESFilter ? translator.buildESFilter(fc.property, fc.value) : '';
            })
            .filter(f => f);

        return [...checkboxFilters, ...mainFilters];
    },
};

export const sqlClauseTranslator = {
    orderBy: (orderBy: SortOrderClause[]) => {
        if (orderBy === null) return '';

        const translateSortOrderForSQL = (sortOrder: SORT_ORDER) =>
            match(sortOrder)
                .with(SORT_ORDER.asc, () => 'asc')
                .with(SORT_ORDER.desc, () => 'desc')
                .with(SORT_ORDER.none, () => '')
                .exhaustive();

        const sqlOrderBy = orderBy
            .map(o => {
                const orderName = orderToQueryTranslations[o.elementId]?.sqlOrderBy ?? o.elementId;
                return `${orderName.replaceAll('.', '/')} ${translateSortOrderForSQL(o.order)}`;
            })
            .join(',');
        return sqlOrderBy;
    },
    filterBy: (dataFilters: FilterClause[], filterCheckboxes: FilterCheckboxes | null) => {
        const filterExprs = filterCheckboxes != null ? buildSQLFiltersFromCheckboxes(filterCheckboxes) : [];
        filterExprs.push(
            ...dataFilters
                .map(fc => {
                    const translator = filterToQueryTranslations[fc.operator.value];
                    return translator.buildFilter ? translator.buildFilter(fc.property, fc.value, fc.type) : '';
                })
                .filter(f => f)
        );

        return filterExprs;
    },
};

export class OemProceduresApiServiceWrapper<TProcedure> {
    readonly expandClause: string;
    readonly oemMetadataProperty: string;
    // TODO: Filter `isTestOem` excluding test procedures (includes one test books) should be remove, but first data in OemId != 100 needs to be cleaned
    readonly isTestOem: boolean;

    readonly SQLFilterClause: string;
    readonly SQLExpandClause: string;
    readonly defaultSQLOrderBy = 'ProcedureId desc';
    readonly SQLSelectClause =
        'IsDeleted,ProcedureTitle,MappingRuleId,ProcedureId,OemId,UpdateDate,StageArea,StageArea/Type,StageArea/Groups';
    readonly SQLTestBookFilter = 'BooksForProcedure/all(b: not b/Book/IsTestBook)';
    readonly SQLProceduresExpand = 'BooksForProcedure($select=Book;$expand=Book($select=BookId,BookName,IsTestBook))';

    readonly esFilterClause: string[];
    readonly defaultESOrderBy = [{ field: 'procedureId', order: 'Descending' }];
    readonly ESSelectClause = [
        'isDeleted',
        'procedureTitle',
        'mappingRuleId',
        'procedureId',
        'oemId',
        'createDate',
        'updateDate',
        'stageArea',
        'booksForProcedure',
        'procedureDetails',
        'vehicles',
        '_timestamp',
        'mappingStatusId',
    ];

    readonly proceduresRoute = '/api/RepairProcedure/odata/Procedure';
    readonly searchRoute = '/api/search/RepairDeckProcedure/_search';
    // as ES all fields treat as array we need to take only these procedures where there is no 'true'
    // it should be read as: NOT any value of BooksForProcedure.IsTestBook IS true
    readonly TestBookFilter = '!booksForProcedure.isTestBook:true';
    readonly ExcludeDeletedProceduresFilter = ['isDeleted:false', 'stageArea.isDeleted:false'];
    readonly ProceduresExpand = 'BooksForProcedure($select=Book;$expand=Book($select=BookId,BookName,IsTestBook))';

    constructor({
        oemIds,
        oemMetadataProperty,
        isTestOem,
    }: {
        oemIds: number[];
        oemMetadataProperty: string;
        isTestOem?: boolean;
    }) {
        this.oemMetadataProperty = oemMetadataProperty;
        this.expandClause = oemMetadataProperty + ',' + this.ProceduresExpand;
        this.isTestOem = isTestOem || false;

        this.esFilterClause = [`${oemIds.map(id => `oemId:${id}`).join(' || ')}`];

        this.SQLFilterClause = '(' + oemIds.map(oemId => `oemId eq ${oemId}`).join(' or ') + ')';
        this.SQLExpandClause = oemMetadataProperty + ',' + this.SQLProceduresExpand;

        if (!this.isTestOem) {
            this.esFilterClause.push(this.TestBookFilter);
            this.SQLFilterClause += ' and ' + this.SQLTestBookFilter;
        }
    }

    private translateOrderByForSql = (orderBy: SortOrderClause[]) => {
        return sqlClauseTranslator.orderBy(orderBy);
    };

    private translateFilterByForSql = (dataFilters: FilterClause[], filterCheckboxes: FilterCheckboxes | null) => {
        return sqlClauseTranslator.filterBy(dataFilters, filterCheckboxes);
    };

    private translateOrderByForES = (orderBy: SortOrderClause[]) => esClauseTranslator.orderBy(orderBy);

    private translateFilterByForES = (dataFilters: FilterClause[], filterCheckboxes: FilterCheckboxes | null) => {
        return esClauseTranslator.filterBy(dataFilters, filterCheckboxes);
    };

    buildFilter = (dataFilters: FilterClause[], filterCheckboxes: FilterCheckboxes | null): string[] => {
        const filter = this.translateFilterByForES(dataFilters, filterCheckboxes);
        return filter.length > 0 ? [...filter, ...this.esFilterClause] : this.esFilterClause;
    };

    buildSQLFilter = (dataFilters: FilterClause[], filterCheckboxes: FilterCheckboxes | null): string => {
        const filter = this.translateFilterByForSql(dataFilters, filterCheckboxes).join(' and ');
        return filter ? `${filter} and ${this.SQLFilterClause}` : this.SQLFilterClause;
    };

    countESProcedures = async (
        dataFilters: FilterClause[],
        filterCheckboxes: FilterCheckboxes | null
    ): Promise<number> => {
        const searchFilters = this.translateFilterByForES(dataFilters, filterCheckboxes);
        const includeDeletedProcedures = filterCheckboxes?.filterRemovedRemovalPending ?? false;

        const params: SearchParams = {
            queryString: '*',
            filters: [
                ...searchFilters,
                ...this.esFilterClause,
                ...(includeDeletedProcedures ? [] : this.ExcludeDeletedProceduresFilter),
            ],
            operator: 'and',
            returnFields: [],
            skip: 0,
            top: 0,
            searchFields: [],
            sorts: [],
            count: true,
        };

        const postProcessParams = this.processHTMLFilter(params);

        const result = await fetchWithAuthHeader(this.searchRoute, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postProcessParams),
        });

        const { TotalResults } = await result.json();

        if (TotalResults == -1) {
            throw new Error('Elasticsearch query error');
        }

        return TotalResults;
    };

    countSQLProcedures = async (
        dataFilters: FilterClause[],
        filterCheckboxes: FilterCheckboxes | null
    ): Promise<number> => {
        const includeDeletedProcedures = filterCheckboxes?.filterRemovedRemovalPending ?? false;

        const stringFilter = this.buildSQLFilter(dataFilters, filterCheckboxes);
        const oDataParams = { filter: stringFilter, top: 0, count: true };
        const result = await RequestRestGetWithOdataParams(
            this.proceduresRoute,
            oDataParams,
            null,
            includeDeletedProcedures === true
                ? {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                      'x-client': 'none',
                  }
                : null
        );
        return result['@odata.count'];
    };

    getProcedureIds = async (
        dataFilters: FilterClause[],
        filterCheckboxes: FilterCheckboxes | null,
        orderBy: SortOrderClause[],
        top: number,
        skip: number
    ): Promise<TProcedure[]> => {
        const searchFilters = this.translateFilterByForES(dataFilters, filterCheckboxes);
        const orderByES = this.translateOrderByForES(orderBy);

        const params: SearchParams = {
            queryString: '*',
            filters: [...searchFilters, ...this.esFilterClause, ...this.ExcludeDeletedProceduresFilter],
            operator: 'and',
            searchFields: [],
            returnFields: ['procedureId'],
            skip: skip,
            top: top,
            sorts: this.buildSorts(searchFilters, orderByES),
            count: false,
        };

        const postProcessParams = this.processHTMLFilter(params);

        const result = await fetchWithAuthHeader(this.searchRoute, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postProcessParams),
        });

        const { TotalResults, Results } = await result.json();

        if (TotalResults == -1) {
            throw new Error('Elasticsearch query error');
        }

        return Results;
    };

    getSQLProcedures = async (
        dataFilters: FilterClause[],
        filterCheckboxes: FilterCheckboxes | null,
        orderBy_: SortOrderClause[],
        top: number,
        skip: number
    ): Promise<OdataResponse<TProcedure[]>> => {
        const orderBy = this.translateOrderByForSql(orderBy_);
        const includeDeletedProcedures = filterCheckboxes?.filterRemovedRemovalPending ?? false;

        const result = await RequestRestGetWithOdataParams(
            this.proceduresRoute,
            {
                select: this.SQLSelectClause,
                filter: this.buildSQLFilter(dataFilters, filterCheckboxes),
                orderBy: orderBy || this.defaultSQLOrderBy,
                top,
                skip,
                expand: this.SQLExpandClause,
                count: false,
            },
            null,
            includeDeletedProcedures
                ? {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                      'x-client': 'none',
                  }
                : null
        );

        return result;
    };

    getESProcedures = async (
        dataFilters: FilterClause[],
        filterCheckboxes: FilterCheckboxes | null,
        orderBy_: SortOrderClause[],
        top: number,
        skip: number,
        count: boolean
    ): Promise<{ totalResults: number; procedures: TProcedure[] }> => {
        const searchFilters = this.translateFilterByForES(dataFilters, filterCheckboxes);
        const orderBy = this.translateOrderByForES(orderBy_);
        const includeDeletedProcedures = filterCheckboxes?.filterRemovedRemovalPending ?? false;

        const params: SearchParams = {
            queryString: '*',
            filters: [
                ...searchFilters,
                ...this.esFilterClause,
                ...(includeDeletedProcedures ? [] : this.ExcludeDeletedProceduresFilter),
            ],
            operator: 'and',
            searchFields: [],
            returnFields: [...this.ESSelectClause, this.oemMetadataProperty],
            skip: skip,
            top: top,
            sorts: this.buildSorts(searchFilters, orderBy),
            count: count,
        };

        const postProcessParams = this.processHTMLFilter(params);

        const result = await fetchWithAuthHeader(this.searchRoute, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postProcessParams),
        });

        const { TotalResults, Results } = await result.json();

        if (TotalResults == -1) {
            throw new Error('Elasticsearch query error');
        }

        return { totalResults: TotalResults, procedures: Results };
    };

    getProceduresStaleData = async (
        proceduresIds: number[]
    ): Promise<OdataResponse<{ procedureId: number; updateDate: string }[]>> => {
        return await RequestRestGetWithOdataParams(this.proceduresRoute, {
            select: 'ProcedureId,UpdateDate',
            filter: `ProcedureId in (${proceduresIds.join(',')})`,
            count: false,
        });
    };

    private processHTMLFilter = (params: SearchParams) => {
        const htmlString = 'html:';
        const HTMLFilter = params.filters.find(f => f.startsWith(htmlString));
        if (!HTMLFilter) return params;
        params.queryString = HTMLFilter.slice(htmlString.length);
        params.searchFields = ['html'];
        params.filters = params.filters.filter(f => f !== HTMLFilter);
        return params;
    };

    private buildSorts = (
        filters: string[],
        orderBy: {
            field: string;
            order: string;
        }[]
    ) => {
        const htmlString = 'html:';
        const HTMLFilter = filters.find(f => f.startsWith(htmlString));
        const scoreField = { field: '_score', order: 'Descending' };
        if (orderBy?.length > 0) return HTMLFilter ? [...orderBy, scoreField] : orderBy;
        else return HTMLFilter ? [scoreField] : this.defaultESOrderBy;
    };
}

export const FordProceduresApiService = new OemProceduresApiServiceWrapper<RpFordProcedure>({
    oemIds: [OemId.Ford],
    oemMetadataProperty: 'latestFordRawProcedure',
});

export const ToyotaProceduresApiService = new OemProceduresApiServiceWrapper<RpToyotaProcedure>({
    oemIds: [OemId.Toyota, OemId.Lexus],
    oemMetadataProperty: 'latestToyotaProcedure',
});

export const NissanProceduresApiService = new OemProceduresApiServiceWrapper<RpNissanProcedure>({
    oemIds: [OemId.Nissan, OemId.Infiniti],
    oemMetadataProperty: 'latestNissanProcedure',
});

export const GMProceduresApiService = new OemProceduresApiServiceWrapper<RpGMProcedure>({
    oemIds: [OemId.GMC],
    oemMetadataProperty: 'latestGMProcedure',
});

export const HondaProceduresApiService = new OemProceduresApiServiceWrapper<RpHondaProcedure>({
    oemIds: [OemId.Honda, OemId.Acura],
    oemMetadataProperty: 'latestHondaProcedure',
});

export const StellantisProceduresApiService = new OemProceduresApiServiceWrapper<RpStellantisProcedure>({
    oemIds: [OemId.Chrysler, OemId.RAM, OemId.Fiat, OemId.Dodge, OemId.Jeep, OemId.AlfaRomeo],
    oemMetadataProperty: 'latestStellantisProcedure',
});

export const VWProceduresApiService = new OemProceduresApiServiceWrapper<RpVWProcedure>({
    oemIds: [OemId.Volkswagen, OemId.Audi],
    oemMetadataProperty: 'latestVWProcedure',
});

export const SubaruProceduresApiService = new OemProceduresApiServiceWrapper<RpSubaruProcedure>({
    oemIds: [OemId.Subaru],
    oemMetadataProperty: 'latestSubaruProcedure',
});

export const HyundaiProceduresApiService = new OemProceduresApiServiceWrapper<RpHyundaiProcedure>({
    oemIds: [OemId.Hyundai, OemId.Genesis],
    oemMetadataProperty: 'latestHyundaiProcedure',
});

export const OemIqFordProceduresApiService = new OemProceduresApiServiceWrapper<RpOemIqFordProcedure>({
    oemIds: [OemId.OEMiQ],
    oemMetadataProperty: 'latestOemIqFordProcedure',
    isTestOem: true,
});
