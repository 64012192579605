import { ProcedurePropertyMetadata } from './ProcedureProperty';

export interface FilterProperty {
    id: string;
    property: string;
    label: string;
    type;
    ruleable: boolean;
    allowInstances: number;
}

export type OemMetadataProperties = ProcedurePropertyMetadata<unknown, unknown>[] & {
    [key: string]: ProcedurePropertyMetadata<unknown, unknown>;
};

export interface OemMetadata {
    oemId: number;
    oemName: string;
    properties: OemMetadataProperties;
    filters: FilterProperty[];
    ruleKey: string;
    ruleable: ProcedurePropertyMetadata<unknown, unknown>[];
}

export const buildOemMetadata = ({
    oemId,
    oemName,
    properties,
    ruleKey,
    filters = null,
}: {
    oemId: number;
    oemName: string;
    properties: ProcedurePropertyMetadata<unknown, unknown>[];
    ruleKey: string;
    filters?: FilterProperty[];
}): OemMetadata => {
    const keyedProperties = [...properties] as OemMetadataProperties;

    for (const el of properties) {
        keyedProperties[el.name] = el;
    }
    return {
        oemId,
        oemName,
        properties: keyedProperties,
        ruleKey,
        ruleable: properties.filter(p => p.ruleable),
        filters: filters ?? [],
    };
};
